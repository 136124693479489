import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, EditButton, Toast, ButtonBase } from 'client-commons';
import { ClientApiUser } from 'types/userTypes';
import { EditUserDialog } from 'pages/users/userDetailsPage/components/userDetails/EditUserDialog';
import { ResetPasswordDialog } from 'pages/users/userDetailsPage/components/userDetails/ResetPasswordDialog';
import { UserInfo } from 'pages/users/userDetailsPage/components/userDetails/UserInfo';

type UserDataPanelProp = {
  userData: ClientApiUser | null;
  fetchUser: () => void;
};

export enum UserDataPanelAction {
  EDIT = 'EDIT',
  RESET_PASSWORD = 'RESET-PASSWORD',
}

export const UserDataPanel = ({ userData, fetchUser }: UserDataPanelProp) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [resetPassDialogOpen, setResetPassDialogOpen] = useState(false);
  const [actionResult, setActionResult] = useState<{
    action?: UserDataPanelAction;
    resolved: boolean;
    status: string;
  }>({ action: undefined, resolved: false, status: '' });

  const closeEditDialog = () => {
    setEditUserDialogOpen(false);
  };

  const closePasswordResetDialog = () => {
    setResetPassDialogOpen(false);
  };

  return (
    <Box className={classes.userDataPanel}>
      <UserInfo userData={userData} />
      {userData && (
        <div className={classes.buttons}>
          <EditButton
            id="edit-user-button"
            className={clsx(classes.editButton, classes.hover)}
            onClick={() => setEditUserDialogOpen(true)}
          />
          <EditUserDialog
            open={editUserDialogOpen}
            onClose={closeEditDialog}
            userData={userData}
            fetchUser={fetchUser}
            setEditResult={setActionResult}
          />
          <div className={classes.rightSide}>
            <ButtonBase
              id="reset-password-button"
              onClick={() => setResetPassDialogOpen(true)}
              className={classes.hover}
              border
            >
              {t('user-page.reset-password')}
            </ButtonBase>
            <ResetPasswordDialog
              open={resetPassDialogOpen}
              onClose={closePasswordResetDialog}
              userEmail={userData?.email}
              setResetResult={setActionResult}
            />
          </div>
        </div>
      )}
      {actionResult.resolved && (
        <Toast
          open={true}
          setState={setActionResult}
          severity={actionResult.status === 'success' ? 'success' : 'error'}
        >
          {t(
            `user-page.${actionResult.action?.toLowerCase()}-${actionResult.status.toLowerCase()}`
          )}
        </Toast>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  userDataPanel: {
    height: '100px',
    maxWidth: '1400px',
  },
  editButton: {
    marginLeft: theme.spacing(3),
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  rightSide: {
    marginRight: theme.spacing(1),
  },
}));
