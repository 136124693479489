import { authApi, clientApi } from 'api/config/interceptors';
import { LoginUserDTO, ResetPasswordDTO } from 'api/auth/authDTOs';

export const loginWithEmail = (loginData: LoginUserDTO) => {
  return authApi.post('/admin/login', loginData).then((res) => {
    return res.data;
  });
};

export const requestPasswordReset = (email: string) => {
  const encodedEmail = encodeURIComponent(email);
  return clientApi
    .get(`/user/resetPassword?email=${encodedEmail}`)
    .then((res) => {
      return res.data;
    });
};

export const resetPassword = (resetPasswordData: ResetPasswordDTO) => {
  return clientApi
    .post(`/user/resetPassword`, resetPasswordData)
    .then((res) => {
      return res.data;
    });
};
