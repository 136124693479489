import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

type LabelValueGrupProps = {
  children: ReactNode;
};

export const LabelValueGrup = ({ children }: LabelValueGrupProps) => {
  const classes = useStyles();

  return <div className={classes.dataItem}>{children}</div>;
};

const useStyles = makeStyles(() => ({
  dataItem: {
    display: 'flex',
  },
}));
