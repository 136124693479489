import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import {
  TextFieldBase,
  SelectBase,
  DialogActions,
  handleBlurAndTrim,
} from 'client-commons';
import { FormikContextType } from 'formik';
import { formatStatusList, ListType } from 'pages/devices/utils/helpers';
import { SensorStatus, SensorGroupList } from 'types/sensorTypes';

export type AddDeviceFormValues = {
  serialNumber: string;
  macAddress: string;
  firmwareVersion: string;
  hardwareRevision: string;
  sensorGroupId: string;
  sensorStatus: SensorStatus;
};

type AddDeviceFormProps = {
  formik: FormikContextType<AddDeviceFormValues>;
  sensorGroups: SensorGroupList;
  handleClose: () => void;
  loading: boolean;
};

export const AddDeviceForm = ({
  formik,
  sensorGroups,
  handleClose,
  loading,
}: AddDeviceFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { errors, values, touched, handleChange } = formik;

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <TextFieldBase
        name="serialNumber"
        id="serial-number"
        label={t('sensors.serial-number')}
        className={classes.input}
        value={values.serialNumber}
        onChange={handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        disabled={loading}
        error={Boolean(errors.serialNumber && touched.serialNumber)}
        helperText={
          errors.serialNumber && touched.serialNumber
            ? errors.serialNumber
            : null
        }
      />
      <TextFieldBase
        name="macAddress"
        id="mac-address"
        label={t('sensors.mac-address')}
        className={classes.input}
        value={values.macAddress}
        onChange={handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        disabled={loading}
        error={Boolean(errors.macAddress && touched.macAddress)}
        helperText={
          errors.macAddress && touched.macAddress ? errors.macAddress : null
        }
      />
      <TextFieldBase
        name="firmwareVersion"
        id="firmware-version"
        label={t('sensors.firmware-version')}
        className={classes.input}
        value={values.firmwareVersion}
        onChange={handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        disabled={loading}
        error={Boolean(errors.firmwareVersion && touched.firmwareVersion)}
        helperText={
          errors.firmwareVersion && touched.firmwareVersion
            ? errors.firmwareVersion
            : null
        }
      />
      <TextFieldBase
        name="hardwareRevision"
        id="hardware-rev"
        label={t('sensors.hardware-rev')}
        className={classes.input}
        value={values.hardwareRevision}
        onChange={handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        disabled={loading}
        error={Boolean(errors.hardwareRevision && touched.hardwareRevision)}
        helperText={
          errors.hardwareRevision && touched.hardwareRevision
            ? errors.hardwareRevision
            : null
        }
      />
      <SelectBase
        name="sensorGroupId"
        id="group-select"
        label={t('sensors.sensor-group')}
        items={sensorGroups}
        className={classes.input}
        value={values.sensorGroupId}
        onChange={handleChange}
        disabled={loading}
      />
      <SelectBase
        name="sensorStatus"
        id="status-select"
        label={t('sensors.sensor-status')}
        items={formatStatusList(ListType.form)}
        className={classes.lastInput}
        value={values.sensorStatus}
        onChange={handleChange}
        disabled={loading}
      />
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2.5),
  },
  lastInput: {
    marginBottom: theme.spacing(5),
  },
  form: {
    marginTop: theme.spacing(3),
  },
}));
