import { ButtonPrimary } from 'client-commons';
import { makeStyles, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'icons/Plus.svg';

export const AddUserButton = ({ onClick }: ButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonPrimary
      startIcon={<Plus />}
      className={classes.addUserButton}
      onClick={onClick}
      id="add-user-button"
    >
      {t('users-page.invite-user')}
    </ButtonPrimary>
  );
};

const useStyles = makeStyles((theme) => ({
  addUserButton: {
    height: '53px',
    marginLeft: theme.spacing(2),
  },
}));
