import { ButtonPrimary } from 'client-commons';
import { makeStyles, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'icons/Plus.svg';

export const AddDeviceButton = ({ onClick }: ButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonPrimary
      startIcon={<Plus />}
      className={classes.addDeviceButton}
      onClick={onClick}
      id="add-device-button"
    >
      {t('sensors.add-device')}
    </ButtonPrimary>
  );
};

const useStyles = makeStyles((theme) => ({
  addDeviceButton: {
    height: '46px',
    marginLeft: theme.spacing(2),
  },
}));
