import { makeStyles, Typography } from '@material-ui/core';
import { LettersAvatar } from 'client-commons';
import { useAuth } from 'context/providers/AuthProvider';

export const UserAvatar = () => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <div className={classes.container}>
      {user && (
        <>
          <LettersAvatar firstName={user.firstName} lastName={user.lastName} />
          <Typography
            id="avatar-user-name"
            align="center"
            className={classes.name}
          >{`${user.firstName} ${user.lastName}`}</Typography>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  name: {
    fontSize: '12px',
    color: theme.palette.grey[900],
  },
}));
