import { ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

type LabelTextProps = {
  children: ReactNode;
};

export const LabelText = ({ children }: LabelTextProps) => {
  const classes = useStyles();

  return (
    <Typography noWrap className={classes.label}>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
}));
