import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResetPasswordDialog } from 'pages/auth/components/ResetPasswordDialog';
import { useAuth } from 'context/providers/AuthProvider';
import { Toast } from 'client-commons';

const ResetPasswordPage = () => {
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const [resetPasswordResult, setResetPasswordResult] = useState<{
    resolved: boolean;
    status: string;
    msg?: string;
  }>({ resolved: false, status: '' });

  useEffect(() => {
    if (user) {
      logout();
    }
  }, [user, logout]);

  return (
    <>
      <ResetPasswordDialog
        open
        setResetPasswordResult={setResetPasswordResult}
        title={t('reset-password-page.header')}
        subtitle={t('reset-password-page.set-new-password')}
      />
      {resetPasswordResult.status === 'error' && (
        <Toast open={true} setState={setResetPasswordResult} severity={'error'}>
          {t('reset-password-page.error')}
        </Toast>
      )}
    </>
  );
};

export default ResetPasswordPage;
