import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TableBodyRow, DataCell } from 'client-commons';
import { Sensor } from 'types/sensorTypes';

type DevicesTableRowProps = {
  sensor: Sensor;
};

export const DevicesTableRow = ({ sensor }: DevicesTableRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    id,
    serialNumber,
    firmwareVersion,
    hardwareRevision,
    sensorGroupName,
    sensorStatus,
    comment,
  } = sensor;

  const handleRowClick = (deviceSerialNo: string) => {
    navigate(`/devices/${deviceSerialNo}`);
  };

  return (
    <TableBodyRow
      key={id}
      id="device-data-row"
      onClick={() => handleRowClick(serialNumber)}
    >
      <DataCell id={`${id}-serial-cell`}>{serialNumber}</DataCell>
      <DataCell id={`${id}-fw-cell`}>{firmwareVersion}</DataCell>
      <DataCell id={`${id}-hw-cell`}>{hardwareRevision}</DataCell>
      <DataCell id={`${id}-group-cell`}>
        {sensorGroupName[0] + sensorGroupName.slice(1).toLocaleLowerCase()}
      </DataCell>
      <DataCell id={`${id}-status-cell`}>
        {t(`sensors.status-${sensorStatus}`)}
      </DataCell>
      <DataCell id={`${id}-comment-cell`}>{comment}</DataCell>
    </TableBodyRow>
  );
};
