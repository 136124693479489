import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { NavWrapper } from 'wrappers/NavWrapper';
import UsersPage from 'pages/users/usersPage/UsersPage';
import BackofficePage from 'pages/backoffice/BackofficePage';
import DevicesPage from 'pages/devices/devicesPage/DevicesPage';
import ClassificationPage from 'pages/classification/ClassificationPage';
import UserDetailsPage from 'pages/users/userDetailsPage/UserDetailsPage';
import DeviceDetailsPage from 'pages/devices/deviceDetailsPage/DeviceDetailsPage';
import WelcomePage from 'pages/auth/welcome/WelcomePage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';

const AuthenticatedApp = () => {
  return (
    <Routes>
      <Route path="/welcome/:userId/:code" element={<WelcomePage />} />
      <Route
        path="/forgot-password/:userId/:code"
        element={<ResetPasswordPage />}
      />
      <Route element={<NavWrapper />}>
        <Route path="/users" element={<Outlet />}>
          <Route index element={<UsersPage />} />
          <Route path=":userId" element={<UserDetailsPage />} />
        </Route>
        <Route path="/expert-classification" element={<ClassificationPage />} />
        <Route path="/devices" element={<Outlet />}>
          <Route index element={<DevicesPage />} />
          <Route path=":deviceSerialNo" element={<DeviceDetailsPage />} />
        </Route>
        <Route path="/backoffice" element={<BackofficePage />} />
        <Route path="*" element={<Navigate to="/users" />} />
      </Route>
    </Routes>
  );
};

export default AuthenticatedApp;
