import { SetStateAction, Dispatch, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'client-commons';
import { DeviceFilters } from 'pages/devices/devicesPage/components/DeviceFilters';
import { AddDeviceButton } from 'pages/devices/devicesPage/components/AddDeviceButton';
import { AddDeviceDialog } from 'pages/devices/devicesPage/components/AddDeviceDialog';
import {
  AddDeviceToast,
  AddResultType,
} from 'pages/devices/devicesPage/components/AddDeviceToast';
import { searchSensors, listSensors } from 'api/sensors/sensorsApi';
import { FilterField } from 'api/sensors/sensorDTOs';
import { Sensor } from 'types/sensorTypes';

type DeviceActionsProps = {
  onFilter: Dispatch<SetStateAction<Sensor[] | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fetchSensors: () => void;
};

export const DeviceActions = ({
  onFilter,
  setLoading,
  fetchSensors,
}: DeviceActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [firmwareValue, setFirmwareValue] = useState('');
  const [hardwareValue, setHardwareValue] = useState('');
  const [groupValue, setGroupValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [serialNoValue, setSerialNoValue] = useState('');
  const [submittedFilters, setSubmittedFilters] = useState<
    { name: FilterField; value: string }[]
  >([]);
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);
  const [addResult, setAddResult] = useState<AddResultType>({
    resolved: false,
    status: '',
  });

  const selectFilters = {
    firmware: { firmwareValue, setFirmwareValue },
    hardware: { hardwareValue, setHardwareValue },
    group: { groupValue, setGroupValue },
    status: { statusValue, setStatusValue },
  };

  const clearFilterValues = () => {
    setFirmwareValue('');
    setHardwareValue('');
    setGroupValue('');
    setStatusValue('');
  };

  const handleSearch = (phrase: string) => {
    clearFilterValues();
    setLoading(true);
    setSubmittedFilters([]);
    const filters = [
      {
        name: FilterField.sensorSerialNumber,
        value: phrase,
      },
    ];
    searchSensors(filters)
      .then((response) => {
        onFilter(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClearSearch = () => {
    setLoading(true);
    listSensors()
      .then((response) => {
        onFilter(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.actionsContainer}>
      <SearchBox
        onSearch={handleSearch}
        onClearSearch={handleClearSearch}
        searchValue={serialNoValue}
        setSearchValue={setSerialNoValue}
        placeholder={t('sensors.sensor-number')}
        id="devices-searchbox"
        className={classes.searchBox}
      />
      <AddDeviceButton onClick={() => setAddDeviceDialogOpen(true)} />
      <AddDeviceDialog
        open={addDeviceDialogOpen}
        onClose={() => setAddDeviceDialogOpen(false)}
        setAddResult={setAddResult}
        fetchSensors={fetchSensors}
      />
      <AddDeviceToast addResult={addResult} setAddResult={setAddResult} />
      <DeviceFilters
        onFilter={onFilter}
        setLoading={setLoading}
        selectFilters={selectFilters}
        clearFilterValues={clearFilterValues}
        submittedFilters={submittedFilters}
        setSubmittedFilters={setSubmittedFilters}
        setSearchboxValue={setSerialNoValue}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  searchBox: {
    minWidth: '300px',
    marginRight: theme.spacing(3),

    '& .MuiOutlinedInput-root': {
      height: '46px',
    },
  },
  actionsContainer: {
    marginTop: theme.spacing(6),
    maxWidth: '1500px',
  },
}));
