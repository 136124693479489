import * as Yup from 'yup';
import { validation } from 'client-commons';

export const profileValidationSchema = (peselValidationCache: {
  [key: string]: boolean;
}) => {
  return Yup.object().shape({
    firstName: validation.firstName(),
    lastName: validation.lastName(),
    govIdentification: validation.govIdentification(peselValidationCache),
    dateOfBirth: validation.dateOfBirth(),
    height: validation.height(),
    weight: validation.weight(),
  });
};
