import { adminApi } from 'api/config/interceptors';
import {
  DeviceFilter,
  EditDeviceDTO,
  AddDeviceDTO,
} from 'api/sensors/sensorDTOs';

export const listSensors = (userId?: string) => {
  const params = {
    sortOrder: 'DESC',
    sortField: 'sensorSerialNumber',
    filters: userId
      ? [{ field: 'ownerId', operand: 'LIKE', value: userId }]
      : [],
  };

  return adminApi
    .get(`/sensor`, {
      params: {
        requestQuery: btoa(unescape(JSON.stringify(params))),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const searchSensors = (filters: DeviceFilter[]) => {
  const nonNullFilters = filters.filter((filter) => filter.value !== '');

  const apiFilters = nonNullFilters.map((filter) => {
    return { field: filter.name, operand: 'LIKE', value: filter.value };
  });

  const params = {
    sortOrder: 'DESC',
    sortField: 'sensorSerialNumber',
    filters: apiFilters,
  };

  return adminApi
    .get(`/sensor`, {
      params: {
        requestQuery: btoa(unescape(JSON.stringify(params))),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getSensorById = (serialNumber: string) => {
  return adminApi.get(`/sensor/${serialNumber}`).then((res) => {
    return res.data;
  });
};

export const addSensor = (values: AddDeviceDTO) => {
  return adminApi.post(`/sensor`, values).then((res) => {
    return res.data;
  });
};

export const editSensor = (serialNumber: string, values: EditDeviceDTO) => {
  return adminApi.put(`/sensor/${serialNumber}`, values).then((res) => {
    return res.data;
  });
};

export const getAvailableFW = () => {
  return adminApi.get(`/sensor/firmware `).then((res) => {
    return res.data;
  });
};

export const getAvailableHW = () => {
  return adminApi.get(`/sensor/hardware`).then((res) => {
    return res.data;
  });
};

export const getAvailableGroups = () => {
  return adminApi.get(`/sensor-group`).then((res) => {
    return res.data;
  });
};

export const getSensorChangelog = (serialNumber: string) => {
  return adminApi.get(`/sensor/${serialNumber}/audit`).then((res) => {
    return res.data;
  });
};
