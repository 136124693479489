import { ReactNode } from 'react';
import { Drawer, makeStyles } from '@material-ui/core';
import { MenuContent } from 'components/Navbar/MenuContent';

const drawerWidth = 247;

type NavbarProps = {
  children: ReactNode;
};

export const Navbar = ({ children }: NavbarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <nav>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <MenuContent />
        </Drawer>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    marginTop: theme.spacing(3),
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 2, 0, 2),
  },
}));
