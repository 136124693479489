import { useTranslation } from 'react-i18next';
import {
  TableBodyRow,
  DataCell,
  formatDurationInSeconds,
  formatDate,
} from 'client-commons';
import { ExaminationSimple } from 'types/examinationTypes';

type ExaminationsTableRowProps = {
  examination: ExaminationSimple;
};

export const ExaminationsTableRow = ({
  examination,
}: ExaminationsTableRowProps) => {
  const { t } = useTranslation();

  const {
    examinationId,
    startDateTime,
    naturalId,
    firstName,
    lastName,
    totalRegistrationTime,
    examinationStatus,
  } = examination;

  return (
    <TableBodyRow id="examination-data-row">
      <DataCell id={`${examinationId}-date-cell`}>
        {formatDate(startDateTime)}
      </DataCell>
      <DataCell id={`${examinationId}-natural-id-cell`}>{naturalId}</DataCell>
      <DataCell
        id={`${examinationId}-profile-name-cell`}
      >{`${firstName} ${lastName}`}</DataCell>
      <DataCell id={`${examinationId}-exam-duration-cell`}>
        {formatDurationInSeconds(totalRegistrationTime)}
      </DataCell>
      <DataCell id={`${examinationId}-status-cell`}>
        {t(`examinations.status-${examinationStatus}`)}
      </DataCell>
      <DataCell id={`${examinationId}-change-profile-cell`}></DataCell>
      <DataCell id={`${examinationId}-actions-cell`}></DataCell>
    </TableBodyRow>
  );
};
