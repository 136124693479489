import i18n from 'translations/i18n';
import { SensorStatus } from 'types/sensorTypes';

export enum ListType {
  filters = 'filters',
  form = 'form',
}

export const formatList = (list: string[], listType: ListType) => {
  const nonNullItems = list.filter((item) => item !== '');
  const formatted = nonNullItems.map((element) => {
    return { name: element, label: element };
  });

  if (listType === ListType.filters) {
    formatted.unshift({ name: '', label: i18n.t('empty') });
  }
  return formatted;
};

export const formatStatusList = (listType: ListType) => {
  const statusList: { name: SensorStatus | ''; label: string }[] =
    Object.values(SensorStatus).map((status) => {
      return {
        name: status,
        label: i18n.t(`sensors.status-${status}`),
      };
    });

  if (listType === ListType.filters) {
    statusList.unshift({ name: '', label: i18n.t('empty') });
  }
  return statusList;
};
