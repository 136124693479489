export type Sensor = {
  comment: string;
  createdDate: string;
  firmwareVersion: string;
  hardwareRevision: string;
  id: string;
  macAddress: string;
  ownerId: string;
  sensorGroupName: string;
  sensorStatus: SensorStatus;
  serialNumber: string;
  targetFirmwareVersion: string;
  lendedToProfile?: SensorBorrower;
  lenderId?: string;
  lendingDate?: string;
  ownershipDate?: string;
};

export enum SensorStatus {
  ACTIVE = 'ACTIVE',
  DESTROYED = 'DESTROYED',
  DEV = 'DEV',
  MAINTENANCE = 'MAINTENANCE',
}

export type SensorBorrower = {
  firstName: string;
  lastName: string;
  lendedAt: string;
  profileId: string;
  returnedAt?: string;
};

export type SensorGroup = {
  id: string;
  name: string;
  sensorsCount: number;
};

export type SelectList = { name: string; label: string }[];

export type ChangelogEntry = {
  author: string;
  authorId: string;
  comment: string;
  commentMod: boolean;
  date: string;
  firmwareVersion: string;
  firmwareVersionMod: boolean;
  hardwareRevision: string;
  hardwareRevisionMod: boolean;
  rev: number;
  sensorId: string;
  status: SensorStatus;
  statusMod: boolean;
};

export type SensorGroupList = {
  name: string;
  label: string;
}[];
