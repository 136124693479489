import { useState } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeadCell, TablePagination } from 'client-commons';
import { isEmpty } from 'lodash';
import { ChangelogTableRow } from 'pages/devices/deviceDetailsPage/components/ChangelogTableRow';
import { NoResultsRow } from 'components/Table/NoResultsRow';
import { ChangelogEntry } from 'types/sensorTypes';

type DeviceChangelogTableProps = {
  changelog: ChangelogEntry[] | null;
  loading: boolean;
};

export const DeviceChangelogTable = ({
  changelog,
  loading,
}: DeviceChangelogTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const rowsPerPage = 10;
  const columnLabels = [
    'changelog-date',
    'changelog-action',
    'comment',
    'changelog-author',
  ];

  if (!changelog && !loading) return null;

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} id="device-changelog-table">
          <TableHead>
            <TableRow>
              {columnLabels.map((label) => (
                <HeadCell id={`${label}-head-cell`} key={label}>
                  {t(`sensors.${label}`)}
                </HeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {changelog && !isEmpty(changelog) ? (
              changelog
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((entry) => {
                  return <ChangelogTableRow entry={entry} key={entry.rev} />;
                })
            ) : (
              <NoResultsRow loading={loading} colSpan={4} />
            )}
          </TableBody>
        </Table>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalRecords={(changelog && changelog.length) || 0}
        />
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    maxWidth: '1400px',
    marginTop: theme.spacing(3),
  },
}));
