import AuthenticatedApp from 'AuthenticatedApp';
import NotAuthenticatedApp from 'NotAuthenticatedApp';
import { useAuth } from 'context/providers/AuthProvider';

const Root = () => {
  const { user, fetchingUser } = useAuth();

  if (fetchingUser) return null;

  return user ? <AuthenticatedApp /> : <NotAuthenticatedApp />;
};

export default Root;
