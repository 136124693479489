import { Dispatch, SetStateAction, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchBox, Toast } from 'client-commons';
import { AddUserButton } from 'pages/users/usersPage/components/AddUserButton';
import { AddUserDialog } from 'pages/users/usersPage/components/AddUserDialog';
import { searchUsers } from 'api/users/usersApi';
import { inviteMsg } from 'api/users/usersDTOs';
import { User } from 'types/userTypes';

type UsersActionsProps = {
  setUsers: Dispatch<SetStateAction<User[] | null>>;
};

export const UsersActions = ({ setUsers }: UsersActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [addUserResult, setAddUserResult] = useState<{
    resolved: boolean;
    status: string;
    msg?: string;
  }>({ resolved: false, status: '' });

  const handleSearch = (phrase: string) => {
    searchUsers(phrase).then((res) => {
      setUsers(res.content);
    });
  };

  const handleClearSearch = () => {
    setUsers(null);
  };

  const renderMessage = () => {
    if (addUserResult.status === 'success') {
      if (addUserResult.msg === inviteMsg.dataNotOverridden) {
        return t('users-page.invite-data-not-overridden');
      }
      return t('users-page.invite-success');
    }
    if (addUserResult.status === 'error') {
      if (addUserResult.msg === inviteMsg.userExists) {
        return t(`users-page.invite-user-exists`);
      }
      return t('users-page.invite-error');
    }
  };

  return (
    <div className={classes.actions}>
      <SearchBox
        onSearch={handleSearch}
        onClearSearch={handleClearSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={t('users-page.search-placeholder')}
        className={classes.searchBox}
        id="users-searchbox"
      />
      <AddUserButton onClick={() => setAddUserDialogOpen(true)} />
      <AddUserDialog
        open={addUserDialogOpen}
        onClose={() => setAddUserDialogOpen(false)}
        setAddUserResult={setAddUserResult}
      />
      {addUserResult.resolved && (
        <Toast
          open={true}
          setState={setAddUserResult}
          severity={addUserResult.status === 'success' ? 'success' : 'error'}
        >
          {renderMessage()}
        </Toast>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  searchBox: {
    width: '600px',
  },
  actions: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1200px',
  },
}));
