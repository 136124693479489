import { useState, useEffect } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { HeadCell, TablePagination, CircularLoader } from 'client-commons';
import { Sensor } from 'types/sensorTypes';
import { NoResultsRow } from 'components/Table/NoResultsRow';
import { DevicesTableRow } from 'pages/devices/devicesPage/components/DevicesTableRow';

type DevicesTableProps = {
  devices: Sensor[] | null;
  loading: boolean;
};

export const DevicesTable = ({ devices, loading }: DevicesTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [devices]);

  const rowsPerPage = 20;
  const columnLabels = [
    'sensor-number',
    'sensor-firmware',
    'sensor-hardware',
    'sensor-group',
    'sensor-status',
    'sensor-last-comment',
  ];

  if (loading) return <CircularLoader id="loader" className={classes.loader} />;

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader id="devices-table">
        <TableHead>
          <TableRow>
            {columnLabels.map((label) => (
              <HeadCell id={`${label}-head-cell`} key={label}>
                {t(`sensors.${label}`)}
              </HeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {devices && !isEmpty(devices) ? (
            devices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sensor: Sensor) => {
                return <DevicesTableRow sensor={sensor} key={sensor.id} />;
              })
          ) : (
            <NoResultsRow colSpan={6} />
          )}
        </TableBody>
      </Table>
      <TablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalRecords={devices?.length || 0}
      />
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  container: {
    maxWidth: '1500px',
    marginTop: theme.spacing(4),
  },
  loader: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(8),
  },
}));
