import { useState, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { FormikContextType } from 'formik';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Gender } from 'types/profileTypes';
import {
  TextFieldBase,
  GenderButtons,
  DateInput,
  DateInputType,
  DialogActions,
  handleBlurAndTrim,
  weightHeightHandleChange,
  capitalizeOnChange,
  handleGovidChange,
  handleDateOfBirthChange,
} from 'client-commons';
import { validatePesel } from 'api/profiles/profilesApi';
import { ProfileFormValues } from 'pages/users/userDetailsPage/components/profiles/EditProfileDialog';

type ProfileFormProps = {
  formik: FormikContextType<ProfileFormValues>;
  peselValidationCache: { [key: string]: boolean };
  setPeselValidationCache: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  handleClose: () => void;
  loading: boolean;
};

export const ProfileForm = ({
  formik,
  peselValidationCache,
  setPeselValidationCache,
  handleClose,
  loading,
}: ProfileFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, handleBlur, handleChange, errors, touched } = formik;
  const [dateInputType, setDateInputType] = useState<DateInputType>('date');

  const setGender = (gender: Gender) => {
    formik.setFieldValue('gender', gender);
  };

  const disablePeselRelatedField = () => {
    if (formik.values.govIdentification.length === 11) {
      return true;
    }
    return false;
  };

  return (
    <>
      <GenderButtons
        gender={formik.values.gender}
        setGender={setGender}
        disabled={disablePeselRelatedField() || loading}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.formContent}>
          <div className={classes.inputColumn}>
            <TextFieldBase
              required
              name="firstName"
              id="first-name-input"
              label={t('first-name')}
              className={classes.input}
              value={values.firstName}
              disabled={loading}
              onChange={(event) => capitalizeOnChange(event, formik)}
              onBlur={(event) => handleBlurAndTrim(event, formik)}
              error={Boolean(errors.firstName && touched.firstName)}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : null
              }
            />
            <DateInput
              required
              name="dateOfBirth"
              id="date-of-birth-input"
              label={t('user-page.date-of-birth')}
              className={classes.input}
              value={values.dateOfBirth}
              dateInputType={dateInputType}
              setDateInputType={setDateInputType}
              onChange={(event) => handleDateOfBirthChange(event, formik)}
              handleBlur={handleBlur}
              disabled={disablePeselRelatedField() || loading}
              error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth
                  ? errors.dateOfBirth
                  : null
              }
            />
            <TextFieldBase
              name="govIdentification"
              id="gov-identification-input"
              label={t('user-page.govid')}
              className={classes.input}
              value={values.govIdentification}
              disabled={
                Boolean(formik.initialValues.govIdentification) || loading
              }
              onChange={(event) =>
                handleGovidChange(
                  event,
                  formik,
                  peselValidationCache,
                  setPeselValidationCache,
                  setDateInputType,
                  setGender,
                  validatePesel
                )
              }
              onBlur={handleBlur}
              error={Boolean(
                errors.govIdentification && touched.govIdentification
              )}
              helperText={
                errors.govIdentification && touched.govIdentification
                  ? errors.govIdentification
                  : null
              }
            />
            <Typography className={classes.requiredInfo} variant="caption">
              {t('validation.required-field')}
            </Typography>
          </div>
          <div className={classes.inputColumn}>
            <TextFieldBase
              required
              name="lastName"
              id="last-name-input"
              label={t('last-name')}
              className={classes.input}
              value={values.lastName}
              disabled={loading}
              onChange={(event) => capitalizeOnChange(event, formik)}
              onBlur={(event) => handleBlurAndTrim(event, formik)}
              error={Boolean(errors.lastName && touched.lastName)}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : null
              }
            />
            <div className={classes.weightHeight}>
              <TextFieldBase
                required
                name="weight"
                id="weight-input"
                label={t('user-page.weight')}
                className={clsx(classes.numberInput, classes.smallInput)}
                value={values.weight}
                type="number"
                disabled={loading}
                onChange={(event) =>
                  weightHeightHandleChange(event, handleChange)
                }
                onBlur={handleBlur}
                error={Boolean(errors.weight && touched.weight)}
                helperText={
                  errors.weight && touched.weight ? errors.weight : null
                }
              />
              <TextFieldBase
                required
                name="height"
                id="height-input"
                label={t('user-page.height')}
                className={clsx(classes.numberInput, classes.smallInput)}
                value={values.height}
                type="number"
                disabled={loading}
                onChange={(event) =>
                  weightHeightHandleChange(event, handleChange)
                }
                onBlur={handleBlur}
                error={Boolean(errors.height && touched.height)}
                helperText={
                  errors.height && touched.height ? errors.height : null
                }
              />
            </div>
          </div>
        </div>
        <DialogActions
          isLoading={loading}
          onCancel={handleClose}
          isValid={formik.isValid}
        />
      </form>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inputColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
  },
  formContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weightHeight: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallInput: {
    width: '48%',
  },
  numberInput: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
    '& [type=number]': {
      '-moz-appearance': 'textfield',
    },
    marginBottom: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  requiredInfo: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));
