export enum UserGroup {
  BASIC = 'BASIC',
  DOCTOR = 'DOCTOR',
  ADMIN = 'ADMIN',
  CLASSIFIER = 'CLASSIFIER',
  RESEARCHER = 'RESEARCHER',
}

export enum UserStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export type User = {
  currentGroupName: UserGroup;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  userStatus: UserStatus;
};

export type ClientApiUser = {
  currentGroup: { name: UserGroup; permissions: string[]; priority: number };
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  userStatus: UserStatus;
};

export enum UserRole {
  BASIC = 'BASIC',
  DOCTOR = 'DOCTOR',
  CLASSIFIER = 'CLASSIFIER',
  ADMIN = 'ADMIN',
}
