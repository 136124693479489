import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { TableBodyRow, DataCell, CircularLoader } from 'client-commons';

type NoResultsRowProps = {
  loading?: boolean;
  colSpan: number;
};

export const NoResultsRow = ({ loading, colSpan }: NoResultsRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableBodyRow id="no-results-row">
      <DataCell
        id="no-results-cell"
        colSpan={colSpan}
        className={classes.centeredCell}
      >
        {loading ? (
          <CircularLoader className={classes.loader} />
        ) : (
          t('no-results')
        )}
      </DataCell>
    </TableBodyRow>
  );
};

const useStyles = makeStyles((theme) => ({
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  loader: {
    color: theme.palette.grey[400],
  },
}));
