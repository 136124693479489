import * as Yup from 'yup';
import { validation } from 'client-commons';
import i18n from 'translations/i18n';

export const addUserValidationSchema = () => {
  return Yup.object().shape({
    firstName: validation.firstName(),
    lastName: validation.lastName(),
    email: validation.email(),
    role: Yup.string().required(i18n.t('validation.required-role')),
  });
};
