import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Profile, Gender } from 'types/profileTypes';
import { DialogBase, emptyStringToNull } from 'client-commons';
import { ProfileForm } from 'pages/users/userDetailsPage/components/profiles/ProfileForm';
import { getProfileById, editProfile } from 'api/profiles/profilesApi';
import { profileValidationSchema } from 'pages/users/validation/editProfileValidationSchema';

type EditProfileDialogProps = {
  profileId: string;
  open: boolean;
  onClose: () => void;
  fetchProfiles: () => void;
  setEditResult: Dispatch<
    SetStateAction<{
      resolved: boolean;
      status: string;
    }>
  >;
};

export type ProfileFormValues = {
  firstName: string;
  dateOfBirth: string;
  govIdentification: string;
  lastName: string;
  weight: string | number;
  height: string | number;
  gender: Gender;
};

export const EditProfileDialog = ({
  profileId,
  open,
  onClose,
  fetchProfiles,
  setEditResult,
}: EditProfileDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState<Profile | null>(null);
  const [peselValidationCache, setPeselValidationCache] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfileById(profileId).then((response) => {
      setProfileData(response);
    });
  }, [profileId]);

  const formik = useFormik<ProfileFormValues>({
    initialValues: {
      firstName: profileData?.firstName || '',
      lastName: profileData?.lastName || '',
      govIdentification: profileData?.govIdentification || '',
      dateOfBirth: profileData?.dateOfBirth || '',
      height: profileData?.height || '',
      weight: profileData?.weight || '',
      gender: profileData?.gender || '',
    },
    onSubmit: (values) => {
      setEditResult({ resolved: false, status: '' });
      const finalValues = emptyStringToNull(values);
      setLoading(true);
      editProfile({ profileId, profileData: finalValues })
        .then(() => {
          handleClose();
          setLoading(false);
          fetchProfiles();
          setEditResult({ resolved: true, status: 'success' });
        })
        .catch(() => {
          setEditResult({ resolved: true, status: 'error' });
          setLoading(false);
        });
    },
    validationSchema: profileValidationSchema(peselValidationCache),
    enableReinitialize: true,
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <DialogBase
      className={classes.profileDialog}
      open={open}
      onClose={handleClose}
      disabled={loading}
      title={t('user-page.edit-profile')}
      id="edit-profile-dialog"
    >
      <ProfileForm
        formik={formik}
        peselValidationCache={peselValidationCache}
        setPeselValidationCache={setPeselValidationCache}
        handleClose={handleClose}
        loading={loading}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  profileDialog: {
    width: '756px',
    height: '462px',
  },
}));
