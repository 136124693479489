import * as Yup from 'yup';
import i18n from 'translations/i18n';

export const addDeviceValidationSchema = () => {
  return Yup.object().shape({
    serialNumber: Yup.string().required(
      i18n.t('validation.required-serial-no')
    ),
    macAddress: Yup.string().required(
      i18n.t('validation.required-mac-address')
    ),
    firmwareVersion: Yup.string().required(i18n.t('validation.required-fw')),
    hardwareRevision: Yup.string().required(i18n.t('validation.required-hw')),
  });
};
