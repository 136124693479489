import { useEffect, useState, useRef } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CircularLoader } from 'client-commons';
import { listSensors } from 'api/sensors/sensorsApi';
import { DevicesTable } from 'pages/devices/devicesPage/components/DevicesTable';
import { DeviceActions } from 'pages/devices/devicesPage/components/DeviceActions';
import { Sensor } from 'types/sensorTypes';

const DevicesPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [devices, setDevices] = useState<Sensor[] | null>(null);
  const [loading, setLoading] = useState(true);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }

    fetchSensors();
  }, []);

  const fetchSensors = () => {
    setLoading(true);
    listSensors()
      .then((sensors) => {
        setDevices(sensors);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant="h2" id="page-header">
        {t('devices')}
      </Typography>
      {loading && firstUpdate.current ? (
        <CircularLoader id="loader" className={classes.loader} />
      ) : (
        <>
          <DeviceActions
            onFilter={setDevices}
            setLoading={setLoading}
            fetchSensors={fetchSensors}
          />
          <DevicesTable devices={devices} loading={loading} />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(8),
  },
}));

export default DevicesPage;
