import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { DialogBase } from 'client-commons';
import { AddDeviceForm } from 'pages/devices/devicesPage/components/AddDeviceForm';
import { addDeviceValidationSchema } from 'pages/devices/validation/addDeviceValidationSchema';
import { addSensor, getAvailableGroups } from 'api/sensors/sensorsApi';
import { SensorStatus, SensorGroup, SensorGroupList } from 'types/sensorTypes';
import {
  AddResultType,
  sensorExistsMsg,
} from 'pages/devices/devicesPage/components/AddDeviceToast';

type AddDeviceDialogProps = {
  open: boolean;
  onClose: () => void;
  setAddResult: Dispatch<SetStateAction<AddResultType>>;
  fetchSensors: () => void;
};

export const AddDeviceDialog = ({
  onClose,
  setAddResult,
  fetchSensors,
  ...props
}: AddDeviceDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sensorGroups, setSensorGroups] = useState<SensorGroupList>([
    { name: '', label: '' },
  ]);

  useEffect(() => {
    getAvailableGroups().then((response: SensorGroup[]) => {
      const groupsList = response.map((group) => {
        return { name: group.id, label: group.name };
      });
      setSensorGroups(groupsList);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serialNumber: '',
      macAddress: '',
      firmwareVersion: '',
      hardwareRevision: '',
      sensorGroupId: sensorGroups[0].name,
      sensorStatus: SensorStatus.DEV,
    },
    onSubmit: (values) => {
      setLoading(true);
      setAddResult({ resolved: false, status: '' });
      addSensor(values)
        .then(() => {
          setLoading(false);
          handleClose();
          fetchSensors();
          setAddResult({ resolved: true, status: 'success' });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data?.simpleDescription === sensorExistsMsg) {
            setAddResult({
              resolved: true,
              status: 'error',
              msg: sensorExistsMsg,
            });
          } else {
            setAddResult({ resolved: true, status: 'error' });
          }
        });
    },
    validationSchema: addDeviceValidationSchema,
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <DialogBase
      title={t('sensors.adding-device')}
      id="add-device-dialog"
      className={classes.dialog}
      onClose={handleClose}
      disabled={loading}
      {...props}
    >
      <AddDeviceForm
        formik={formik}
        handleClose={handleClose}
        sensorGroups={sensorGroups}
        loading={loading}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '460px',
  },
}));
