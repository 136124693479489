import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SelectBase } from 'client-commons';
import { makeStyles } from '@material-ui/core';
import {
  getAvailableFW,
  getAvailableHW,
  getAvailableGroups,
} from 'api/sensors/sensorsApi';
import { SensorGroup, SelectList } from 'types/sensorTypes';
import {
  formatList,
  formatStatusList,
  ListType,
} from 'pages/devices/utils/helpers';

type FilterSetter = Dispatch<SetStateAction<string>>;

export type SelectFiltersType = {
  firmware: { firmwareValue: string; setFirmwareValue: FilterSetter };
  hardware: { hardwareValue: string; setHardwareValue: FilterSetter };
  group: { groupValue: string; setGroupValue: FilterSetter };
  status: { statusValue: string; setStatusValue: FilterSetter };
};

type SelectFiltersProps = {
  filters: SelectFiltersType;
};

export const SelectFilters = ({ filters }: SelectFiltersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [firmwareList, setFirmwareList] = useState<SelectList>([]);
  const [hardwareList, setHardwareList] = useState<SelectList>([]);
  const [groupsList, setGroupsList] = useState<SelectList>([]);

  useEffect(() => {
    getAvailableFW().then((response: string[]) => {
      setFirmwareList(formatList(response, ListType.filters));
    });
    getAvailableHW().then((response: string[]) => {
      setHardwareList(formatList(response, ListType.filters));
    });
    getAvailableGroups().then((response: SensorGroup[]) => {
      const groupNamesList = response.map((group) => {
        return group.name;
      });
      setGroupsList(formatList(groupNamesList, ListType.filters));
    });
  }, [t]);

  const handleChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    setter: FilterSetter
  ) => {
    setter(event.target.value as string);
  };

  const selectFilters = [
    {
      value: filters.firmware.firmwareValue,
      items: firmwareList,
      setter: filters.firmware.setFirmwareValue,
      label: t('sensors.firmware'),
      id: 'firmware-filter',
    },
    {
      value: filters.hardware.hardwareValue,
      items: hardwareList,
      setter: filters.hardware.setHardwareValue,
      label: t('sensors.hardware'),
      id: 'hardware-filter',
    },
    {
      value: filters.group.groupValue,
      items: groupsList,
      setter: filters.group.setGroupValue,
      label: t('sensors.sensor-group'),
      id: 'group-filter',
    },
    {
      value: filters.status.statusValue,
      items: formatStatusList(ListType.filters),
      setter: filters.status.setStatusValue,
      label: t('sensors.sensor-status'),
      id: 'status-filter',
    },
  ];

  return (
    <>
      {selectFilters.map((filter) => {
        return (
          <SelectBase
            key={filter.label}
            value={filter.value}
            onChange={(event) => handleChange(event, filter.setter)}
            items={filter.items}
            label={filter.label}
            className={classes.select}
            id={filter.id}
          />
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    marginRight: theme.spacing(3),
    minWidth: '80px',
  },
}));
