import { FC, ReactNode } from 'react';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { theme } from 'client-commons';
import { AuthProvider } from 'context/providers/AuthProvider';

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <HashRouter>{children}</HashRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
