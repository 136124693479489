import { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UsersTable } from 'pages/users/usersPage/components/UsersTable';
import { User } from 'types/userTypes';
import { UsersActions } from 'pages/users/usersPage/components/UsersActions';

const UsersPage = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[] | null>(null);

  return (
    <>
      <Typography variant="h2" id="page-header">
        {t('users')}
      </Typography>
      <UsersActions setUsers={setUsers} />
      <UsersTable users={users} />
    </>
  );
};

export default UsersPage;
