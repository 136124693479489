import { useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { DialogBase } from 'client-commons';
import { Sensor } from 'types/sensorTypes';
import { editSensor } from 'api/sensors/sensorsApi';
import { NotEditableFields } from 'pages/devices/deviceDetailsPage/components/NotEditableFields';
import {
  EditDeviceForm,
  EditDeviceValues,
} from 'pages/devices/deviceDetailsPage/components/EditDeviceForm';

type EditDialogProps = {
  open: boolean;
  onClose: () => void;
  device: Sensor;
  fetchSensor: () => void;
  fetchChangelog: () => void;
  setEditResult: Dispatch<
    SetStateAction<{
      resolved: boolean;
      status: string;
    }>
  >;
};

export const EditDeviceDialog = ({
  device,
  onClose,
  setEditResult,
  fetchSensor,
  fetchChangelog,
  ...props
}: EditDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const initialValues: EditDeviceValues = {
    firmwareVersion: device.firmwareVersion,
    sensorGroupName: device.sensorGroupName,
    sensorStatus: device.sensorStatus,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setEditResult({ resolved: false, status: '' });
      setLoading(true);
      if (comment) {
        values.comment = comment;
      }
      editSensor(device.serialNumber, values)
        .then(() => {
          setLoading(false);
          handleClose();
          fetchSensor();
          fetchChangelog();
          setEditResult({ resolved: true, status: 'success' });
        })
        .catch(() => {
          setEditResult({ resolved: true, status: 'error' });
          setLoading(false);
        });
    },
  });

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      formik.resetForm();
      setComment('');
    }, 100);
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  return (
    <DialogBase
      title={`${t('sensors.edit-sensor')} ${device.serialNumber}`}
      onClose={handleClose}
      className={classes.dialog}
      disabled={loading}
      id="edit-device-dialog"
      {...props}
    >
      <NotEditableFields device={device} />
      <EditDeviceForm
        formik={formik}
        loading={loading}
        handleClose={handleClose}
        comment={comment}
        handleCommentChange={handleCommentChange}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '300px',
    maxWidth: '400px',
  },
}));
