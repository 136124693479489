import { useState } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  HeadCell,
  TableBodyRow,
  DataCell,
  TablePagination,
} from 'client-commons';
import { User } from 'types/userTypes';
import { isEmpty } from 'lodash';

type UsersTableProps = {
  users: User[] | null;
};

export const UsersTable = ({ users }: UsersTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const rowsPerPage = 10;
  const columnLabels = ['full-name', 'e-mail', 'account-status', 'role'];

  const handleRowClick = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader id="users-table">
        <TableHead>
          <TableRow>
            {columnLabels.map((label) => (
              <HeadCell id={`${label}-head-cell`} key={label}>
                {t(`users-page.${label}`)}
              </HeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users && !isEmpty(users) ? (
            users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user: User) => {
                return (
                  <TableBodyRow
                    key={user.id}
                    id="user-data-row"
                    onClick={() => handleRowClick(user.id)}
                  >
                    <DataCell
                      id={`${user.id}-name-cell`}
                    >{`${user.firstName} ${user.lastName}`}</DataCell>
                    <DataCell id={`${user.id}-email-cell`}>
                      {user.email}
                    </DataCell>
                    <DataCell id={`${user.id}-status-cell`}>
                      {t(`users-page.${user.userStatus}`)}
                    </DataCell>
                    <DataCell id={`${user.id}-group-cell`}>
                      {user.currentGroupName}
                    </DataCell>
                  </TableBodyRow>
                );
              })
          ) : (
            <TableBodyRow>
              <DataCell
                id="info-cell"
                colSpan={4}
                className={classes.centeredCell}
              >
                {users && isEmpty(users)
                  ? t('no-results')
                  : t('users-page.start-search')}
              </DataCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalRecords={users?.length || 0}
      />
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  container: {
    maxWidth: '1200px',
    marginTop: theme.spacing(3),
  },
}));
