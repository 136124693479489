import { SensorStatus } from 'types/sensorTypes';

export enum FilterField {
  sensorSerialNumber = 'sensorSerialNumber',
  hardwareRevision = 'hardwareRevision',
  firmwareVersion = 'firmwareVersion',
  groupName = 'groupName',
  sensorStatus = 'sensorStatus',
}

export type DeviceFilter = {
  name: FilterField;
  value: string;
};

export type EditDeviceDTO = {
  firmwareVersion: string;
  sensorGroupName: string;
  sensorStatus: SensorStatus;
  comment?: string;
};

export type AddDeviceDTO = {
  firmwareVersion: string;
  hardwareRevision: string;
  macAddress: string;
  sensorGroupId: string;
  sensorStatus: SensorStatus;
  serialNumber: string;
};
