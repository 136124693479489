import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { LoggedInUser } from 'types/authTypes';
import { setupInterceptors } from 'api/config/interceptors';
import { decodeToken } from 'utils/decodeJWT';

export type AuthContextValue = {
  user?: LoggedInUser;
  login: (user: LoggedInUser) => void;
  logout: () => void;
  fetchingUser: boolean;
};

export type AuthProviderProps = {
  children: ReactNode;
};

const defaultValue: AuthContextValue = {
  user: undefined,
  login: () => {},
  logout: () => {},
  fetchingUser: true,
};

const AuthContext = createContext(defaultValue);

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const [user, setUser] = useState<LoggedInUser | undefined>(undefined);
  const [fetchingUser, setFetchingUser] = useState(true);

  const login = useCallback((userData) => {
    setUser({ ...userData });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(undefined);
  }, []);

  useMemo(() => {
    setupInterceptors(logout);
  }, [logout]);

  useEffect(() => {
    const checkIfUserLoggedIn = () => {
      if (!localStorage.token || !decodeToken(localStorage.token)) {
        logout();
        return;
      }
      const decodedUser = decodeToken(localStorage.token);
      login(decodedUser);
    };
    checkIfUserLoggedIn();
    setFetchingUser(false);
  }, [login, logout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        fetchingUser,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, AuthContext, useAuth };
