import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Typography, Container } from '@material-ui/core';
import { LoginForm } from 'pages/auth/login/LoginForm';
import {
  ButtonPrimaryBig,
  Toast,
  CircularLoader,
  CheckboxWithLabel,
} from 'client-commons';
import { loginValidationSchema } from 'pages/auth/validation/loginValidationSchema';
import { loginWithEmail } from 'api/auth/authApi';
import { useAuth } from 'context/providers/AuthProvider';

const LoginPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginResult, setLoginResult] = useState<{
    resolved: boolean;
    status: string;
  }>({ resolved: false, status: '' });
  const [loading, setLoading] = useState(false);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      setInitialValues({ email, password: '' });
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setLoginResult({ resolved: false, status: '' });
      setLoading(true);
      loginWithEmail(values)
        .then((user) => {
          login(user);
          setLoading(false);
          navigate('/users');
        })
        .catch(() => {
          setLoginResult({ resolved: true, status: 'error' });
          setLoading(false);
        });
      if (rememberMeChecked) {
        localStorage.setItem('userEmail', values.email);
      }
    },
    validationSchema: loginValidationSchema,
  });

  const handleRememberMeClick = () => {
    setRememberMeChecked(!rememberMeChecked);
  };

  return (
    <Container className={classes.container}>
      <img
        src="images/clebre-logoP293.png"
        alt="clebre logo"
        className={classes.logo}
      />
      <Typography
        className={classes.welcomeText}
        variant="h2"
        id="login-welcome-text"
      >
        {t('login-page.welcome-text')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <LoginForm formik={formik} />
        <CheckboxWithLabel
          checked={rememberMeChecked}
          onChange={handleRememberMeClick}
          name="rememberMe"
          label={t('login-page.remember-me')}
        />
        <ButtonPrimaryBig
          type="submit"
          id="submit-button"
          className={classes.loginButton}
          fullWidth
        >
          {loading ? <CircularLoader /> : t('login-page.login')}
        </ButtonPrimaryBig>
      </form>
      <Toast
        open={loginResult.status === 'error'}
        setState={setLoginResult}
        severity="error"
      >
        {t('login-page.login-error')}
      </Toast>
      <Typography className={classes.footer}>Terms of use © Clebre</Typography>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '370px',
    height: '100vh',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '160px',
    marginBottom: theme.spacing(8),
  },
  welcomeText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(6),
    textAlign: 'center',
    fontWeight: 600,
  },
  loginButton: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  footer: {
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
}));

export default LoginPage;
