import { makeStyles } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

type CustomLinkProps = LinkProps & {
  label: string;
  onClick?: () => void;
  className?: string;
};
export const CustomLink = ({ label, className, ...props }: CustomLinkProps) => {
  const classes = useStyles();
  return (
    <Link {...props} className={clsx(className, classes.linkText)}>
      {label}
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  linkText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    width: 'fit-content',
    textDecoration: 'none',
    display: 'block',
  },
}));
