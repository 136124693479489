import { useEffect, useState, useCallback } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserDataPanel } from 'pages/users/userDetailsPage/components/userDetails/UserDataPanel';
import { ProfilesTable } from 'pages/users/userDetailsPage/components/profiles/ProfilesTable';
import { ExaminationsTable } from 'pages/users/userDetailsPage/components/examinations/ExaminationsTable';
import { SensorsTable } from 'pages/users/userDetailsPage/components/sensors/SensorsTable';
import { useParams } from 'react-router-dom';
import { getUser } from 'api/users/usersApi';
import { ClientApiUser } from 'types/userTypes';

const UserDetailsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userId } = useParams<{ userId: string }>();
  const [userData, setUserData] = useState<ClientApiUser | null>(null);

  const fetchUser = useCallback(() => {
    if (!userId) {
      return;
    }
    getUser(userId).then((data) => {
      setUserData(data);
    });
  }, [userId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <>
      <UserDataPanel userData={userData} fetchUser={fetchUser} />
      {userData?.id && (
        <>
          <Typography variant="h2" className={classes.tableHeader}>
            {t('profiles.header')}
          </Typography>
          <ProfilesTable userId={userData.id} />
          <Typography variant="h2">{t('examinations.header')}</Typography>
          <ExaminationsTable userId={userData.id} />
          <Typography variant="h2">{t('sensors.header')}</Typography>
          <SensorsTable userId={userData.id} />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    marginTop: theme.spacing(2),
  },
}));

export default UserDetailsPage;
