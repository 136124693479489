import { useState, useEffect, MouseEvent, useCallback } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeadCell, TablePagination, Toast } from 'client-commons';
import { ProfileSimple } from 'types/profileTypes';
import { isEmpty } from 'lodash';
import { listProfiles } from 'api/profiles/profilesApi';
import { EditProfileDialog } from 'pages/users/userDetailsPage/components/profiles/EditProfileDialog';
import { ProfilesTableRow } from 'pages/users/userDetailsPage/components/profiles/ProfilesTableRow';
import { NoResultsRow } from 'components/Table/NoResultsRow';

type ProfilesTableProps = {
  userId: string;
};

export const ProfilesTable = ({ userId }: ProfilesTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState<ProfileSimple[] | null>(null);
  const [page, setPage] = useState(0);
  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<string | null>(null);
  const [editResult, setEditResult] = useState<{
    resolved: boolean;
    status: string;
  }>({ resolved: false, status: '' });
  const [loading, setLoading] = useState(true);

  const rowsPerPage = 10;
  const columnLabels = [
    'full-name',
    'examinations-number',
    'last-exam-date',
    'sensors',
    'edit',
  ];

  const fetchProfiles = useCallback(() => {
    listProfiles(userId)
      .then((response) => {
        setProfiles(response.content);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const handleEditClick = (
    event: MouseEvent<HTMLButtonElement>,
    profileId: string
  ) => {
    setCurrentProfile(profileId);
    setEditProfileDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditProfileDialogOpen(false);
    setCurrentProfile(null);
  };

  if (!profiles && !loading) return null;

  return (
    <>
      <TableContainer className={classes.container}>
        <div className={classes.fixedTableSize}>
          <Table className={classes.table} id="profiles-table">
            <TableHead>
              <TableRow>
                {columnLabels.map((label) => (
                  <HeadCell
                    id={`${label}-head-cell`}
                    key={label}
                    className={label === 'edit' ? classes.editCell : undefined}
                  >
                    {t(`profiles.${label}`)}
                  </HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles && !isEmpty(profiles) ? (
                profiles
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((profile) => {
                    return (
                      <ProfilesTableRow
                        profile={profile}
                        onEditClick={handleEditClick}
                        key={profile.profileId}
                      />
                    );
                  })
              ) : (
                <NoResultsRow loading={loading} colSpan={5} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalRecords={(profiles && profiles.length) || 0}
        />
      </TableContainer>
      {currentProfile && (
        <EditProfileDialog
          open={editProfileDialogOpen}
          onClose={handleDialogClose}
          profileId={currentProfile}
          fetchProfiles={fetchProfiles}
          setEditResult={setEditResult}
        />
      )}
      {editResult.resolved && (
        <Toast
          open={true}
          setState={setEditResult}
          severity={editResult.status === 'success' ? 'success' : 'error'}
        >
          {editResult.status === 'success'
            ? t('user-page.edit-profile-success')
            : t('user-page.edit-profile-error')}
        </Toast>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  fixedTableSize: {
    minHeight: '420px',
    backgroundColor: theme.palette.grey[500],
  },
  editCell: {
    textAlign: 'center',
    width: '100px',
  },
  container: {
    maxWidth: '1400px',
    marginTop: theme.spacing(3),
  },
}));
