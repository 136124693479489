import { useTranslation } from 'react-i18next';
import { TableBodyRow, DataCell, formatDate } from 'client-commons';
import { ChangelogEntry } from 'types/sensorTypes';

type ChangelogTableRowProps = {
  entry: ChangelogEntry;
};

enum ActionType {
  commentMod = 'commentMod',
  firmwareVersionMod = 'firmwareVersionMod',
  hardwareRevisionMod = 'hardwareRevisionMod',
  statusMod = 'statusMod',
}

export const ChangelogTableRow = ({ entry }: ChangelogTableRowProps) => {
  const { t } = useTranslation();

  const { rev, date, comment, author } = entry;

  const actions = Object.keys(ActionType);
  const performedActions = actions.filter((action) => {
    return entry[action as ActionType];
  });

  return (
    <TableBodyRow id="changelog-data-row">
      <DataCell id={`${rev}-date-cell`}>{formatDate(date)}</DataCell>
      <DataCell id={`${rev}-action-cell`}>
        {performedActions.map((action, index) => {
          let endChar = ', ';
          if (index === performedActions.length - 1) {
            endChar = '';
          }
          return t(`sensors.changelog-${action}`).concat(endChar);
        })}
      </DataCell>
      <DataCell id={`${rev}-comment-cell`}>{comment}</DataCell>
      <DataCell id={`${rev}-author-cell`}>{author}</DataCell>
    </TableBodyRow>
  );
};
