import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { validation } from 'client-commons';

export const loginValidationSchema = () => {
  return Yup.object().shape({
    email: validation.email(),
    password: Yup.string().required(i18n.t('validation.required-password')),
  });
};
