import { adminApi } from 'api/config/interceptors';

export const listExaminations = (userId: string) => {
  const params = {
    sortOrder: 'DESC',
    sortField: 'startDateTime',
  };

  return adminApi
    .get(`/user/${userId}/examination`, {
      params: {
        requestQuery: btoa(unescape(JSON.stringify(params))),
      },
    })
    .then((res) => {
      return res.data;
    });
};
