import {
  useState,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { DialogProps, Typography, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoDialog, SubmitButton, PasswordInput } from 'client-commons';
import { setPasswordValidationSchema } from 'pages/auth/validation/setPasswordValidationSchema';
import { resetPassword } from 'api/auth/authApi';
import { useAuth } from 'context/providers/AuthProvider';

type ResetPasswordDialogProps = DialogProps & {
  setResetPasswordResult: Dispatch<
    SetStateAction<{
      resolved: boolean;
      status: string;
      msg?: string;
    }>
  >;
  title: string;
  subtitle: string;
};

export const ResetPasswordDialog = ({
  open,
  setResetPasswordResult,
  title,
  subtitle,
}: ResetPasswordDialogProps) => {
  const { userId, code } = useParams<{ userId: string; code: string }>();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!code || !userId) {
      navigate('/');
    }
  }, [code, userId, navigate]);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: (values) => {
      setResetPasswordResult({ resolved: false, status: '' });
      setLoading(true);
      const resetPasswordData = {
        password: values.password,
        resetCode: code!,
        userId: userId!,
      };
      resetPassword(resetPasswordData)
        .then((userData) => {
          navigate('/');
          login(userData);
        })
        .catch(() => {
          setLoading(false);
          setResetPasswordResult({ resolved: true, status: 'error' });
        });
    },
    validationSchema: setPasswordValidationSchema(password),
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <InfoDialog open={open}>
      <Typography className={classes.heading} variant="h2">
        {title}
      </Typography>
      <Typography className={classes.message}>{subtitle}</Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <PasswordInput
          disabled={loading}
          className={classes.input}
          value={formik.values.password}
          onChange={handlePasswordChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password && formik.touched.password}
          helperText={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : null
          }
        />
        <PasswordInput
          disabled={loading}
          className={classes.input}
          name="passwordConfirm"
          id="password-confirm"
          label={t('repeat-password')}
          value={formik.values.passwordConfirm}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.errors.passwordConfirm && formik.touched.passwordConfirm
          }
          helperText={
            formik.errors.passwordConfirm && formik.touched.passwordConfirm
              ? formik.errors.passwordConfirm
              : null
          }
        />
        <SubmitButton
          type="submit"
          id="submit-button"
          className={classes.button}
          disabled={!formik.dirty || !formik.isValid || loading}
          isLoading={loading}
        >
          {t('submit')}
        </SubmitButton>
      </form>
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 0, 2, 0),
  },
  message: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  input: {
    margin: theme.spacing(3, 0, 3, 0),
    width: '327px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(4, 0, 2, 0),
  },
}));
