import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Box, EditButton, Toast } from 'client-commons';
import { Sensor } from 'types/sensorTypes';
import { EditDeviceDialog } from 'pages/devices/deviceDetailsPage/components/EditDeviceDialog';
import { DeviceInfo } from 'pages/devices/deviceDetailsPage/components/DeviceInfo';

type DeviceInfoProps = {
  device: Sensor | null;
  fetchSensor: () => void;
  fetchChangelog: () => void;
};

export const DeviceInfoPanel = ({
  device,
  fetchSensor,
  fetchChangelog,
}: DeviceInfoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editResult, setEditResult] = useState<{
    resolved: boolean;
    status: string;
  }>({ resolved: false, status: '' });

  return (
    <Box className={classes.container}>
      <DeviceInfo device={device} />
      {device && (
        <>
          <EditButton
            onClick={() => setEditDialogOpen(true)}
            className={classes.editButton}
            id="device-edit-button"
          />
          <EditDeviceDialog
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            device={device}
            setEditResult={setEditResult}
            fetchSensor={fetchSensor}
            fetchChangelog={fetchChangelog}
          />
        </>
      )}
      {editResult.resolved && (
        <Toast
          open={true}
          setState={setEditResult}
          severity={editResult.status === 'success' ? 'success' : 'error'}
        >
          {editResult.status === 'success'
            ? t('sensors.edit-sensor-success')
            : t('sensors.edit-sensor-error')}
        </Toast>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0, 3, 0),
    width: 'fit-content',
    minWidth: '274px',
    maxWidth: '400px',
    alignItems: 'flex-start',
    minHeight: '176px',
  },
  editButton: {
    marginLeft: theme.spacing(4),
  },
}));
