import { useEffect, useState, ChangeEvent } from 'react';
import { FormikContextType } from 'formik';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  SelectBase,
  TextFieldBase,
  DialogActions,
  TextArea,
} from 'client-commons';
import { SensorStatus, SensorGroup, SelectList } from 'types/sensorTypes';
import { getAvailableGroups } from 'api/sensors/sensorsApi';
import {
  formatList,
  formatStatusList,
  ListType,
} from 'pages/devices/utils/helpers';

export type EditDeviceValues = {
  firmwareVersion: string;
  sensorGroupName: string;
  sensorStatus: SensorStatus;
  comment?: string;
};

type EditDeviceFormProps = {
  formik: FormikContextType<EditDeviceValues>;
  loading: boolean;
  handleClose: () => void;
  comment: string;
  handleCommentChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const EditDeviceForm = ({
  formik,
  loading,
  handleClose,
  comment,
  handleCommentChange,
}: EditDeviceFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [groupsList, setGroupsList] = useState<SelectList>([
    { name: 'DEFAULT', label: 'DEFAULT' },
  ]);

  useEffect(() => {
    getAvailableGroups().then((response: SensorGroup[]) => {
      const groupNamesList = response.map((group) => {
        return group.name;
      });
      setGroupsList(formatList(groupNamesList, ListType.form));
    });
  }, [t]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldBase
        name="firmwareVersion"
        id="firmware-input"
        label={t('sensors.firmware')}
        value={formik.values.firmwareVersion}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={classes.input}
        maxLength={30}
        disabled={loading}
      />
      <SelectBase
        name="sensorGroupName"
        id="group-select"
        label={t('sensors.sensor-group')}
        items={groupsList}
        value={formik.values.sensorGroupName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={classes.input}
        disabled={loading}
      />
      <SelectBase
        name="sensorStatus"
        id="status-select"
        label={t('sensors.sensor-status')}
        items={formatStatusList(ListType.form)}
        value={formik.values.sensorStatus}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={classes.input}
        disabled={loading}
      />
      <TextArea
        label={t('sensors.comment')}
        value={comment}
        onChange={(event) => handleCommentChange(event)}
        maxLength={500}
        rows={5}
        id="comment-input"
        className={classes.textArea}
        disabled={loading}
      />
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty || Boolean(comment)}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  textArea: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));
