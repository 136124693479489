import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  DialogBase,
  TextFieldBase,
  DialogActions,
  handleBlurAndTrim,
  capitalizeOnChange,
} from 'client-commons';
import { ClientApiUser } from 'types/userTypes';
import { editUserValidationSchema } from 'pages/users/validation/editUserValidationSchema';
import { editUser } from 'api/users/usersApi';
import { UserDataPanelAction } from 'pages/users/userDetailsPage/components/userDetails/UserDataPanel';

type EditUserDialogProps = {
  userData: ClientApiUser;
  open: boolean;
  onClose: () => void;
  setEditResult: Dispatch<
    SetStateAction<{
      action?: UserDataPanelAction;
      resolved: boolean;
      status: string;
    }>
  >;
  fetchUser: () => void;
};

export const EditUserDialog = ({
  userData,
  onClose,
  setEditResult,
  fetchUser,
  ...props
}: EditUserDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    onClose();
    setTimeout(() => {
      setLoading(false);
      formik.resetForm();
    }, 200);
  };

  const initialValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setLoading(true);
      setEditResult({ action: undefined, resolved: false, status: '' });
      const apiValues = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      editUser(userData.id, apiValues)
        .then(() => {
          closeDialog();
          fetchUser();
          setEditResult({
            action: UserDataPanelAction.EDIT,
            resolved: true,
            status: 'success',
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setEditResult({
            action: UserDataPanelAction.EDIT,
            resolved: true,
            status: 'error',
          });
        });
    },
    validationSchema: editUserValidationSchema,
  });

  return (
    <DialogBase
      title={t('user-page.edit-user')}
      id="edit-user-dialog"
      onClose={closeDialog}
      className={classes.dialog}
      disabled={loading}
      {...props}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFieldBase
          name="firstName"
          id="first-name"
          disabled={loading}
          value={formik.values.firstName}
          label={t('first-name')}
          className={classes.input}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.firstName && formik.touched.firstName)}
          helperText={
            formik.errors.firstName && formik.touched.firstName
              ? formik.errors.firstName
              : null
          }
        />
        <TextFieldBase
          name="lastName"
          id="last-name"
          disabled={loading}
          value={formik.values.lastName}
          label={t('last-name')}
          className={classes.input}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.lastName && formik.touched.lastName)}
          helperText={
            formik.errors.lastName && formik.touched.lastName
              ? formik.errors.lastName
              : null
          }
        />
        <TextFieldBase
          name="email"
          id="email"
          disabled
          value={formik.values.email}
          label={t('email')}
          className={classes.input}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.email && formik.touched.email)}
          helperText={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : null
          }
        />
        <DialogActions
          onCancel={onClose}
          isLoading={loading}
          isValid={formik.dirty && formik.isValid}
        />
      </form>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '490px',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}));
