import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { decodeToken } from 'utils/decodeJWT';

const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL,
});

const clientApi = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_API_URL,
});

const setupInterceptors = (logout: () => void) => {
  authApi.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  adminApi.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  adminApi.interceptors.request.use((config) => {
    return addAuthorizationHeader(config, logout);
  });

  clientApi.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  clientApi.interceptors.request.use((config) => {
    return addAuthorizationHeader(config, logout);
  });
};

const updateToken = (response: AxiosResponse) => {
  if (response.headers.authorization) {
    localStorage.setItem('token', `${response.headers.authorization}`);
  }
  return response;
};

const addAuthorizationHeader = (
  config: AxiosRequestConfig,
  logout: () => void
) => {
  const token = localStorage.getItem('token');
  const newConfig = { ...config };
  if (token && decodeToken(token) && newConfig.headers) {
    newConfig.headers.Authorization = token;
  } else {
    logout();
  }
  return newConfig;
};

export { authApi, adminApi, clientApi, setupInterceptors };
