import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { DialogBase, TextFieldBase, DialogActions } from 'client-commons';
import { requestPasswordReset } from 'api/auth/authApi';
import { UserDataPanelAction } from 'pages/users/userDetailsPage/components/userDetails/UserDataPanel';

type ResetPasswordDialogProps = {
  userEmail: string | undefined;
  open: boolean;
  onClose: () => void;
  setResetResult: Dispatch<
    SetStateAction<{
      action?: UserDataPanelAction;
      resolved: boolean;
      status: string;
    }>
  >;
};

export const ResetPasswordDialog = ({
  userEmail,
  onClose,
  setResetResult,
  ...props
}: ResetPasswordDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    onClose();
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const initialValues = {
    email: userEmail,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setResetResult({ action: undefined, resolved: false, status: '' });
      if (!values.email) return;
      setLoading(true);
      requestPasswordReset(values.email)
        .then(() => {
          closeDialog();
          setResetResult({
            action: UserDataPanelAction.RESET_PASSWORD,
            resolved: true,
            status: 'success',
          });
        })
        .catch(() => {
          setLoading(false);
          setResetResult({
            action: UserDataPanelAction.RESET_PASSWORD,
            resolved: true,
            status: 'error',
          });
        });
    },
  });

  return (
    <DialogBase
      title={t('user-page.reset-password')}
      id="reset-password-dialog"
      onClose={closeDialog}
      className={classes.dialog}
      disabled={loading}
      {...props}
    >
      <Typography className={classes.info}>
        {t('user-page.reset-password-info')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextFieldBase
          name="email"
          id="email"
          disabled
          value={formik.values.email}
          label={t('email')}
          className={classes.input}
        />
        <DialogActions onCancel={onClose} isLoading={loading} isValid />
      </form>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '490px',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  info: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));
