import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type MenuItemProps = {
  name: string;
  path: string;
  icon: ReactNode;
  selected: boolean;
  component?: any;
  target?: string;
};

export const MenuItem = ({
  name,
  path,
  icon,
  selected,
  component,
  target,
}: MenuItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem
      className={classes.listItem}
      component={component}
      target={target}
      href={path}
      to={path}
      key={name}
      selected={selected}
      id={`${name}-menu-item`}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={t(name)} classes={{ primary: classes.text }} />
    </ListItem>
  );
};

MenuItem.defaultProps = {
  component: RouterLink,
  target: undefined,
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: theme.spacing(3, 0, 3, 0),
    height: '60px',
    color: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.background,
    },
  },
  text: {
    lineHeight: 1.3,
    marginLeft: -theme.spacing(1),
  },
}));
