import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'client-commons';

export type AddResultType = {
  resolved: boolean;
  status: string;
  msg?: string;
};

type AddDeviceToastProps = {
  addResult: AddResultType;
  setAddResult: Dispatch<SetStateAction<AddResultType>>;
};

export const sensorExistsMsg = 'Sensor already exists.';

export const AddDeviceToast = ({
  addResult,
  setAddResult,
}: AddDeviceToastProps) => {
  const { t } = useTranslation();

  const renderMessage = () => {
    if (addResult.status === 'success') {
      return t('sensors.add-device-success');
    }
    if (addResult.status === 'error') {
      if (addResult.msg === sensorExistsMsg) {
        return t('sensors.device-already-exists');
      }
      return t('sensors.add-device-error');
    }
  };

  return (
    <Toast
      open={addResult.resolved}
      setState={setAddResult}
      severity={addResult.status === 'success' ? 'success' : 'error'}
    >
      {renderMessage()}
    </Toast>
  );
};
