import { SetStateAction, Dispatch } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonBase } from 'client-commons';
import {
  SelectFilters,
  SelectFiltersType,
} from 'pages/devices/devicesPage/components/SelectFilters';
import { searchSensors } from 'api/sensors/sensorsApi';
import { FilterField } from 'api/sensors/sensorDTOs';
import { Sensor } from 'types/sensorTypes';

type DeviceFiltersProps = {
  onFilter: Dispatch<SetStateAction<Sensor[] | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  selectFilters: SelectFiltersType;
  clearFilterValues: () => void;
  submittedFilters: { name: FilterField; value: string }[];
  setSubmittedFilters: Dispatch<
    SetStateAction<{ name: FilterField; value: string }[]>
  >;
  setSearchboxValue: Dispatch<SetStateAction<string>>;
};

enum ActionType {
  submit = 'submit',
  clear = 'clear',
}

export const DeviceFilters = ({
  onFilter,
  setLoading,
  selectFilters,
  clearFilterValues,
  submittedFilters,
  setSubmittedFilters,
  setSearchboxValue,
}: DeviceFiltersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleFiltersSubmit = (type: ActionType.clear | ActionType.submit) => {
    setLoading(true);
    setSearchboxValue('');
    const filters =
      type === ActionType.clear
        ? []
        : [
            {
              name: FilterField.firmwareVersion,
              value: selectFilters.firmware.firmwareValue,
            },
            {
              name: FilterField.hardwareRevision,
              value: selectFilters.hardware.hardwareValue,
            },
            {
              name: FilterField.groupName,
              value: selectFilters.group.groupValue,
            },
            {
              name: FilterField.sensorStatus,
              value: selectFilters.status.statusValue,
            },
          ];
    setSubmittedFilters(filters);
    searchSensors(filters)
      .then((response) => {
        onFilter(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const clearFilters = () => {
    clearFilterValues();

    handleFiltersSubmit(ActionType.clear);
  };

  return (
    <>
      <div className={classes.filtersContainer}>
        <SelectFilters filters={selectFilters} />
        <ButtonPrimary
          className={classes.submitButton}
          onClick={() => handleFiltersSubmit(ActionType.submit)}
          id="filter-button"
        >
          {t('filter')}
        </ButtonPrimary>
      </div>
      {submittedFilters.length > 0 && (
        <ButtonBase
          onClick={clearFilters}
          className={classes.clear}
          id="clear-filters-button"
        >
          {t('clear-filters')}
        </ButtonBase>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  submitButton: {
    minWidth: '120px',
  },
  clear: {
    marginTop: theme.spacing(4),
  },
}));
