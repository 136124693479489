import { useEffect, useState, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeviceInfoPanel } from 'pages/devices/deviceDetailsPage/components/DeviceInfoPanel';
import { DeviceChangelogTable } from 'pages/devices/deviceDetailsPage/components/DeviceChangelogTable';
import { getSensorById, getSensorChangelog } from 'api/sensors/sensorsApi';
import { ChangelogEntry } from 'types/sensorTypes';

const DeviceDetailsPage = () => {
  const { t } = useTranslation();
  const { deviceSerialNo } = useParams<{ deviceSerialNo: string }>();
  const [sensor, setSensor] = useState(null);
  const [changelog, setChangelog] = useState<ChangelogEntry[] | null>(null);
  const [changelogLoading, setChangelogLoading] = useState(true);

  const fetchSensor = useCallback(() => {
    if (!deviceSerialNo) {
      return;
    }
    getSensorById(deviceSerialNo).then((response) => {
      setSensor(response);
    });
  }, [deviceSerialNo]);

  const fetchChangelog = useCallback(() => {
    if (!deviceSerialNo) {
      return;
    }
    getSensorChangelog(deviceSerialNo)
      .then((response: ChangelogEntry[]) => {
        const sorted = response.sort((a, b) => (a.date < b.date ? 1 : -1));
        setChangelog(sorted);
        setChangelogLoading(false);
      })
      .catch(() => {
        setChangelogLoading(false);
      });
  }, [deviceSerialNo]);

  useEffect(() => {
    fetchSensor();
    fetchChangelog();
  }, [fetchSensor, fetchChangelog]);

  return (
    <>
      <Typography variant="h2" id="page-header">
        {t('sensors.details')}
      </Typography>
      <DeviceInfoPanel
        device={sensor}
        fetchSensor={fetchSensor}
        fetchChangelog={fetchChangelog}
      />
      <Typography variant="h3">{t('sensors.changelog')}</Typography>
      <DeviceChangelogTable changelog={changelog} loading={changelogLoading} />
    </>
  );
};

export default DeviceDetailsPage;
