import { Button, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import { ReactComponent as Logout } from 'icons/Logout.svg';

export const LogoutButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <Button
        startIcon={<Logout />}
        className={classes.logout}
        onClick={handleLogout}
        id="logout-button"
      >
        {t('logout')}
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  logout: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: theme.palette.grey[700],
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5, 3, 1.5, 3),

    '&:hover': {
      backgroundColor: theme.palette.primary.background,
    },
  },
}));
