import { MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { TableBodyRow, DataCell, IconButton, formatDate } from 'client-commons';
import { ReactComponent as Edit } from 'icons/Edit.svg';
import { ProfileSimple } from 'types/profileTypes';

type ProfilesTableRowProps = {
  profile: ProfileSimple;
  onEditClick: (
    event: MouseEvent<HTMLButtonElement>,
    profileId: string
  ) => void;
};

export const ProfilesTableRow = ({
  profile,
  onEditClick,
}: ProfilesTableRowProps) => {
  const classes = useStyles();
  const {
    profileId,
    firstName,
    lastName,
    countOfExaminations,
    dateOfLastExamination,
    sensorSerialNumber,
  } = profile;

  return (
    <TableBodyRow id="profile-data-row">
      <DataCell
        id={`${profileId}-name-cell`}
      >{`${firstName} ${lastName}`}</DataCell>
      <DataCell id={`${profileId}-examNo-cell`}>{countOfExaminations}</DataCell>
      <DataCell id={`${profileId}-last-exam-date-cell`}>
        {dateOfLastExamination && formatDate(dateOfLastExamination)}
      </DataCell>
      <DataCell id={`${profileId}-sensors-cell`}>
        {sensorSerialNumber.map((sensor) => {
          return (
            <span key={sensor} className={classes.sensorItem}>
              {sensor}
            </span>
          );
        })}
      </DataCell>
      <DataCell id={`${profileId}-edit-cell`} className={classes.editCell}>
        <IconButton
          className={classes.editButton}
          onClick={(event) => onEditClick(event, profileId)}
          id={`${profileId}-edit-button`}
        >
          <Edit />
        </IconButton>
      </DataCell>
    </TableBodyRow>
  );
};

const useStyles = makeStyles((theme) => ({
  editCell: {
    textAlign: 'center',
    width: '100px',
    padding: theme.spacing(0.5),
  },
  editButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 2, 0.5, 2),
  },
  sensorItem: {
    display: 'block',
  },
}));
