import { UserRole } from 'types/userTypes';

export type InviteUserDTO = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
};

export type UpdateUserDTO = {
  firstName: string;
  lastName: string;
};

export const inviteMsg = {
  userExists: 'User already exists',
  dataNotOverridden: 'data-not-overridden',
};

export type InviteUserResponseDTO = {
  email: string;
  firstInvite: boolean;
  firstName: string;
  lastName: string;
  role: UserRole;
};
