import { useTranslation } from 'react-i18next';
import { TableBodyRow, DataCell } from 'client-commons';
import { Sensor } from 'types/sensorTypes';

type SensorsTableRowProps = {
  sensor: Sensor;
};

export const SensorsTableRow = ({ sensor }: SensorsTableRowProps) => {
  const { t } = useTranslation();

  const {
    id,
    serialNumber,
    lendedToProfile,
    firmwareVersion,
    hardwareRevision,
    sensorGroupName,
    sensorStatus,
  } = sensor;

  return (
    <TableBodyRow id="sensors-data-row">
      <DataCell id={`${id}-serial-no-cell`}>{serialNumber}</DataCell>
      <DataCell id={`${id}-profile-cell`}>
        {lendedToProfile &&
          `${lendedToProfile?.firstName} ${lendedToProfile?.lastName}`}
      </DataCell>
      <DataCell id={`${id}-fw-version-cell`}>{firmwareVersion}</DataCell>
      <DataCell id={`${id}-hw-revision-cell`}>{hardwareRevision}</DataCell>
      <DataCell id={`${id}-group-cell`}>
        {sensorGroupName[0] + sensorGroupName.slice(1).toLocaleLowerCase()}
      </DataCell>
      <DataCell id={`${id}-status-cell`}>
        {t(`sensors.status-${sensorStatus}`)}
      </DataCell>
    </TableBodyRow>
  );
};
