import { useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeadCell, TablePagination } from 'client-commons';
import { ExaminationSimple } from 'types/examinationTypes';
import { isEmpty } from 'lodash';
import { listExaminations } from 'api/examinations/examinationsApi';
import { ExaminationsTableRow } from 'pages/users/userDetailsPage/components/examinations/ExaminationsTableRow';
import { NoResultsRow } from 'components/Table/NoResultsRow';

type ExaminationsTableProps = {
  userId: string;
};

export const ExaminationsTable = ({ userId }: ExaminationsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [examinations, setExaminations] = useState<ExaminationSimple[] | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const rowsPerPage = 10;
  const columnLabels = [
    'exam-date',
    'exam-number',
    'exam-profile',
    'exam-duration',
    'exam-status',
    'change-profile',
    'actions',
  ];

  const fetchExaminations = useCallback(() => {
    listExaminations(userId)
      .then((response) => {
        setExaminations(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    fetchExaminations();
  }, [fetchExaminations]);

  if (!examinations && !loading) return null;

  return (
    <>
      <TableContainer className={classes.container}>
        <div className={classes.fixedTableSize}>
          <Table className={classes.table} id="examinations-table">
            <TableHead>
              <TableRow>
                {columnLabels.map((label) => (
                  <HeadCell id={`${label}-head-cell`} key={label}>
                    {t(`examinations.${label}`)}
                  </HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {examinations && !isEmpty(examinations) ? (
                examinations
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((examination) => {
                    return (
                      <ExaminationsTableRow
                        examination={examination}
                        key={examination.examinationId}
                      />
                    );
                  })
              ) : (
                <NoResultsRow loading={loading} colSpan={7} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalRecords={(examinations && examinations.length) || 0}
        />
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    maxWidth: '1400px',
    marginTop: theme.spacing(3),
  },
  fixedTableSize: {
    minHeight: '400px',
    backgroundColor: theme.palette.grey[500],
  },
}));
