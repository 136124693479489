import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LabelText } from 'components/Other/LabelText';
import { LabelValueGrup } from 'components/Other/LabelValueGrup';
import { Sensor } from 'types/sensorTypes';

type NotEditableFieldsProps = {
  device: Sensor;
};

export const NotEditableFields = ({ device }: NotEditableFieldsProps) => {
  const { t } = useTranslation();

  const {
    firmwareVersion,
    targetFirmwareVersion,
    hardwareRevision,
    lendedToProfile,
  } = device;

  return (
    <>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.current-fw')}:`}</LabelText>
        <Typography id="firmware-version">{firmwareVersion}</Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.target-fw')}:`}</LabelText>
        <Typography id="target-fw">
          {targetFirmwareVersion ? targetFirmwareVersion : t('empty')}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.hardware')}:`}</LabelText>
        <Typography id="hardware-revision">{hardwareRevision}</Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-account')}:`}</LabelText>
        <Typography id="sensor-profile">
          {lendedToProfile
            ? `${lendedToProfile?.firstName} ${lendedToProfile?.lastName}`
            : t('empty')}
        </Typography>
      </LabelValueGrup>
    </>
  );
};
