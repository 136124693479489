import { adminApi } from 'api/config/interceptors';
import { EditProfileDTO } from 'api/profiles/profilesDTOs';

export const listProfiles = (userId: string) => {
  const params = {
    sortOrder: 'ASC',
    sortField: 'firstName',
    offset: 0,
    size: 9999,
    filters: [{ field: 'createdBy', operand: 'LIKE', value: userId }],
  };

  return adminApi
    .get(`/profile`, {
      params: {
        requestQuery: btoa(unescape(JSON.stringify(params))),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getProfileById = (profileId: string) => {
  return adminApi.get(`/profile/${profileId}`).then((res) => {
    return res.data;
  });
};

export const validatePesel = (pesel: string) => {
  return adminApi.get(`/validators/pesel/${pesel}`).then((response) => {
    return response.data;
  });
};

export const editProfile = ({ profileId, profileData }: EditProfileDTO) => {
  return adminApi.patch(`/profile/${profileId}`, profileData).then((res) => {
    return res.data;
  });
};
