import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { makeStyles } from '@material-ui/core';
import { TextFieldWithIcon, PasswordInput } from 'client-commons';
import { ReactComponent as Mail } from 'icons/Mail.svg';

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  formik: FormikContextType<LoginValues>;
};

export const LoginForm = ({ formik }: LoginFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <TextFieldWithIcon
        name="email"
        id="email-input"
        label={t('email')}
        className={classes.input}
        icon={<Mail />}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.email && formik.touched.email)}
        helperText={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }
      />
      <PasswordInput
        className={classes.input}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.password && formik.touched.password)}
        helperText={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : null
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(4),
  },
}));
