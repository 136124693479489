import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, List, Box } from '@material-ui/core';
import { MenuItem } from 'components/Navbar/MenuItem';
import { ReactComponent as User } from 'icons/User.svg';
import { ReactComponent as Notepad } from 'icons/Notepad.svg';
import { ReactComponent as Sensor } from 'icons/Sensor.svg';
import { ReactComponent as Settings } from 'icons/Settings.svg';
import { LogoutButton } from 'components/Navbar/LogoutButton';
import { UserAvatar } from 'components/Navbar/UserAvatar';

const menuItems = [
  {
    name: 'users',
    paths: ['/users'],
    icon: <User />,
  },
  {
    name: 'classification',
    paths: ['/expert-classification'],
    icon: <Notepad />,
  },
  {
    name: 'devices',
    paths: ['/devices'],
    icon: <Sensor />,
  },
  {
    name: 'backoffice',
    paths: ['/backoffice'],
    icon: <Settings />,
  },
];

export type Location = {
  pathname: string;
};

const calcSelectedItem = (location: Location) => {
  let selectedItem = 0;
  menuItems.forEach((item, index) => {
    item.paths.forEach((path) => {
      if (location.pathname.includes(path)) {
        selectedItem = index;
      }
    });
  });
  return selectedItem;
};

export const MenuContent = () => {
  const classes = useStyles();
  const location = useLocation();

  const selectedItem = calcSelectedItem(location);

  return (
    <div className={classes.drawerContainer}>
      <Box>
        <RouterLink to="/users">
          <img
            src="images/clebre-logoP293.png"
            alt="clebre logo"
            className={classes.logo}
          />
        </RouterLink>
        <List className={classes.menuList}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.name}
              name={item.name}
              path={item.paths[0]}
              icon={item.icon}
              selected={selectedItem === index}
            />
          ))}
        </List>
      </Box>
      <Box className={classes.bottomItems}>
        <UserAvatar />
        <LogoutButton />
      </Box>
    </div>
  );
};

MenuContent.defaultProps = {
  expanded: true,
};

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'block',
    margin: theme.spacing(0, 'auto', 5, 'auto'),
    width: '109px',
  },
  menuList: {
    padding: theme.spacing(0, 4, 0, 4),
  },
  bottomItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
  },
}));
