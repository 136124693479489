import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ClassificationPage = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h2" id="page-header">
      {t('classification')}
    </Typography>
  );
};

export default ClassificationPage;
