import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'components/Other/Link';
import { LabelText } from 'components/Other/LabelText';
import { LabelValueGrup } from 'components/Other/LabelValueGrup';
import { Sensor, SensorBorrower } from 'types/sensorTypes';

type DeviceInfoProps = {
  device: Sensor | null;
};

export const DeviceInfo = ({ device }: DeviceInfoProps) => {
  const { t } = useTranslation();

  let serialNumber,
    firmwareVersion,
    hardwareRevision,
    sensorGroupName,
    sensorStatus,
    lendedToProfile: SensorBorrower | undefined,
    ownerId: string;

  if (device) {
    serialNumber = device.serialNumber;
    firmwareVersion = device.firmwareVersion;
    hardwareRevision = device.hardwareRevision;
    sensorGroupName = device.sensorGroupName;
    sensorStatus = device.sensorStatus;
    lendedToProfile = device.lendedToProfile;
    ownerId = device.ownerId;
  }

  type AssignedAccountProps = {
    id: string;
  };

  const AssignedAccount = ({ id }: AssignedAccountProps) => {
    if (lendedToProfile && ownerId) {
      return (
        <CustomLink
          to={`/users/${ownerId}`}
          label={`${lendedToProfile?.firstName} ${lendedToProfile?.lastName}`}
          id={id}
        />
      );
    } else if (lendedToProfile) {
      return (
        <Typography
          id={id}
        >{`${lendedToProfile?.firstName} ${lendedToProfile?.lastName}`}</Typography>
      );
    } else {
      return (
        <Typography noWrap id={id}>
          {t('empty')}
        </Typography>
      );
    }
  };

  return (
    <div>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor')}:`}</LabelText>
        <Typography noWrap id="sensor-serial">
          {serialNumber}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-firmware')}:`}</LabelText>
        <Typography noWrap id="sensor-fw">
          {firmwareVersion}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-hardware')}:`}</LabelText>
        <Typography noWrap id="sensor-hw">
          {hardwareRevision}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-group')}:`}</LabelText>
        <Typography noWrap id="sensor-group">
          {sensorGroupName &&
            sensorGroupName[0] + sensorGroupName.slice(1).toLocaleLowerCase()}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-status')}:`}</LabelText>
        <Typography noWrap id="sensor-status">
          {sensorStatus && t(`sensors.status-${sensorStatus}`)}
        </Typography>
      </LabelValueGrup>
      <LabelValueGrup>
        <LabelText>{`${t('sensors.sensor-account')}:`}</LabelText>
        {lendedToProfile !== undefined && (
          <AssignedAccount id="sensor-account" />
        )}
      </LabelValueGrup>
    </div>
  );
};
