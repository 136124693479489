import { adminApi } from 'api/config/interceptors';
import { unescape } from 'lodash';
import { InviteUserDTO, UpdateUserDTO } from 'api/users/usersDTOs';

export const searchUsers = (searchPhrase: string) => {
  const params = {
    sortOrder: 'DESC',
    sortField: 'firstName',
    offset: 0,
    size: 9999,
    filters: [{ field: 'phrase', operand: 'LIKE', value: searchPhrase }],
  };

  return adminApi
    .get(`/user/search`, {
      params: {
        requestQuery: btoa(unescape(JSON.stringify(params))),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getUser = (userId: string) => {
  return adminApi.get(`/user/${userId}`).then((res) => {
    return res.data;
  });
};

export const inviteUser = (userData: InviteUserDTO) => {
  return adminApi.post(`/user/invite`, userData).then((res) => {
    return res.data;
  });
};

export const editUser = (userId: string, userData: UpdateUserDTO) => {
  return adminApi.put(`/user/${userId}`, userData).then((res) => {
    return res.data;
  });
};
