import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from 'pages/auth/login/LoginPage';
import WelcomePage from 'pages/auth/welcome/WelcomePage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';

const NotAuthenticatedApp = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/welcome/:userId/:code" element={<WelcomePage />} />
      <Route
        path="/forgot-password/:userId/:code"
        element={<ResetPasswordPage />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default NotAuthenticatedApp;
