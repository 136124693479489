import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const BackofficePage = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h2" id="page-header">
      {t('backoffice')}
    </Typography>
  );
};

export default BackofficePage;
