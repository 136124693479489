import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  DialogBase,
  TextFieldWithIcon,
  TextFieldBase,
  SelectBase,
  DialogActions,
  capitalizeOnChange,
  handleBlurAndTrim,
} from 'client-commons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Mail } from 'icons/Mail.svg';
import { UserRole } from 'types/userTypes';
import { addUserValidationSchema } from 'pages/users/validation/addUserValidationSchema';
import { inviteUser } from 'api/users/usersApi';
import { InviteUserResponseDTO, inviteMsg } from 'api/users/usersDTOs';

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
  setAddUserResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string; msg?: string }>
  >;
};

export const AddUserDialog = ({
  onClose,
  setAddUserResult,
  ...props
}: AddUserDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const menuItems = Object.values(UserRole).map((role) => {
    return t(role);
  });

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      formik.resetForm();
    }, 100);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: UserRole.BASIC,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setAddUserResult({ resolved: false, status: '' });
      setLoading(true);
      inviteUser(values)
        .then((user: InviteUserResponseDTO) => {
          handleClose();
          setTimeout(() => {
            setLoading(false);
          }, 100);
          if (!user.firstInvite) {
            setAddUserResult({
              resolved: true,
              status: 'success',
              msg: inviteMsg.dataNotOverridden,
            });
          } else {
            setAddUserResult({ resolved: true, status: 'success' });
          }
        })
        .catch((error) => {
          setLoading(false);
          setAddUserResult({
            resolved: true,
            status: 'error',
            msg: error.response?.data?.simpleDescription,
          });
        });
    },
    validationSchema: addUserValidationSchema,
  });

  return (
    <DialogBase
      title={t('users-page.invite-user')}
      className={classes.dialog}
      onClose={handleClose}
      id="add-user-dialog"
      {...props}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFieldBase
          name="firstName"
          id="first-name"
          disabled={loading}
          value={formik.values.firstName}
          label={t('first-name')}
          className={classes.input}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.firstName && formik.touched.firstName)}
          helperText={
            formik.errors.firstName && formik.touched.firstName
              ? formik.errors.firstName
              : null
          }
        />
        <TextFieldBase
          name="lastName"
          id="last-name"
          disabled={loading}
          value={formik.values.lastName}
          label={t('last-name')}
          className={classes.input}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.lastName && formik.touched.lastName)}
          helperText={
            formik.errors.lastName && formik.touched.lastName
              ? formik.errors.lastName
              : null
          }
        />
        <TextFieldWithIcon
          name="email"
          id="email-input"
          disabled={loading}
          label={t('email')}
          className={classes.input}
          icon={<Mail />}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.email && formik.touched.email)}
          helperText={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : null
          }
        />
        <SelectBase
          name="role"
          id="role-select"
          disabled={loading}
          value={formik.values.role}
          items={menuItems}
          label={t('users-page.role')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.input}
        />
        <DialogActions
          onCancel={handleClose}
          isLoading={loading}
          isValid={formik.dirty && formik.isValid}
        />
      </form>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '490px',
    maxWidth: '490px',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}));
