import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ClientApiUser } from 'types/userTypes';

type UserInfoProps = {
  userData: ClientApiUser | null;
};

export const UserInfo = ({ userData }: UserInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.dataBox}>
        <Typography className={classes.label}>
          {t('users-page.full-name')}
        </Typography>
        <Typography
          noWrap
          variant="h3"
          id="user-name"
          className={classes.userName}
        >
          {userData && `${userData.firstName} ${userData.lastName}`}
        </Typography>
      </div>
      <div className={classes.dataBox}>
        <Typography className={classes.label}>
          {t('users-page.role')}
        </Typography>
        <Typography variant="h3" id="user-role" className={classes.userName}>
          {userData?.currentGroup.name}
        </Typography>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  userName: {
    color: theme.palette.grey[900],
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  dataBox: {
    margin: theme.spacing(0, 3, 0, 1),
    maxWidth: '400px',
  },
}));
